<template>
  <!-- header -->
  <nav role="navigation" aria-label="main navigation"
    class="navbar is-fixed-top is-primary"><!-- is-dark -->
    <div class="navbar-brand">
      <a class="navbar-item" href="#">
        <img  style="border-radius:50%" 
              src="./assets/logo.png" alt="Home" />
      </a>
      <!-- <a class="navbar-item" href="#">Tutorworks</a> -->
      <i v-if="userId" class="navbar-item" :title="userId" >{{userName}}</i>
    </div>

      <hr class="navbar-divider">

      <a v-if="userId" class="navbar-item" href="#/attend" >Attendance</a><!-- does not work :disabled="! userId" -->
      <a v-if="userId" class="navbar-item" href="#/links">Learning Links</a>


    <div class="navbar-end">
      <a class="navbar-item button is-primary" href="#/login" v-if="userId">Change Login</a>
      <a class="navbar-item button is-primary" href="#/login" v-else>Login</a>
    </div>
  </nav>
  <!-- <a href="#/non-existent-path">Broken Link</a> -->
  <component :is="currentView" />
</template>

<script>
import Attend from './components/attend.vue'
import NotFound from './components/NotFound.vue'
import LearnLinks from './components/LearnLinks.vue'
import AppLogin from './components/AppLogin.vue'

const routes = {
  '/': AppLogin,
  '/attend': Attend,
  '/links': LearnLinks,
  '/login': AppLogin,
}

export default {
  inject: ['meta'],
  data() {
    return {
      currentPath: window.location.hash,
      title: 'Tutorworks Learning App'
    }
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || '/'] || NotFound
    },
    userId () {
      return this.$root.$data.userId
    },
    userName () {
      return this.$root.$data.userName
    },
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash
    })
  },
  created() {
    this.meta.setTitle(this.title);
  }
}
</script>

