<template>
  <div v-if="isReplaySupported" class="slidecontainer border">
    <button @click="simulate">Replay</button>
    <div v-show="isSimulating">
      <div style="float: left; width: 30px"> 
        <button class="d-block ml-0 mr-auto" @click="startSim">▶</button>
      </div>
      <div style="float: left; width: 30px"> 
        <button class="d-block ml-0 mr-auto" @click="resetElapsed()">◾</button>
      </div>
      <!-- <input type="range" :min="timestart" :max="timeend" v-model.number="timecur" class="slider" id="myRange"> -->
      <p><span>Virtual Clock (local time):{{replayClock}}</span><span v-if="isReplaying">🔁</span></p><!-- {{ticksElapsed}} -->
    </div>
  </div>
  <p style="margin-bottom:1cm;"></p>

  <div v-show="timeclock">
    Clock: {{clock}}
  </div>

  <div class="scrollingtable">
    <div>
      <div class="tableparent">

        <div class="table-container">
          <table class="table is-bordered is-hoverable">
          <caption class="is-size-5">
            Student Roster for <i class="is-size-4" :title="'Class ID:' + curClassId">{{curClassName}} </i>
            <span title="Seen P Marked / Total">
            ({{seenTodayCount}} P {{presentToday}} / {{sortedMembers.length}})   
            </span>
            <button class="button is-small is-info is-rounded is-light" @click="onChangeClass">Change Class</button>
            <button class="button is-small is-info is-rounded is-light" @click="onReport">Report</button>
          </caption>
          <thead>
              <tr>
                <th v-for="colH in colHeaders" :key="colH" class="has-text-centered" title="E = ETCO login&#10;P = Present&#10;A = Absent">
                  <!-- <div :label="colH"></div> -->
                  {{ colH }}
                </th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="mem in sortedMembers" :key="mem.id">
                  <td class="firstcol has-text-left"
                    :style="[mem.seenCount||mem.attendsConfirmed[0] ? {'background':'yellow'} : {}]"
                    :title="tooltip(mem)">
                      <a :href="'https://etco.readingsolved.com:444/rptStudent.aspx?s='+mem.id" target="_blank">
                        {{mem.fn +' ' + mem.ln}}
                      </a>
                  </td>
                  <td @click="openNoteModal(mem)" :style="[mem.noteStatsOpen? {'background':'yellow','cursor':'pointer'}:{'cursor':'pointer'}]"
                    title="Click to see Notes for this Student">
                    {{formatNoteStats(mem.noteStatsOpen, mem.noteStatsClosed)}}
                  </td>
                  <td @click="showAct(mem.lastact)" :style="{'cursor':'pointer'}" title="Click to View in Actions list below">
                    {{formatLastact(mem.lastact)}}
                  </td>
                  <td class="is-size-7">{{formatLastUnit(mem.lastact)}}</td>
                  <td>
                    {{mem.grade}}
                  </td>
                  <td class="has-text-left is-size-7">
                    {{mem.gLn}}
                  </td>
                  <td class="has-text-left is-size-7">
                    {{mem.gFn}}
                  </td>
                  <td>
                    {{mem.country}}
                  </td>
                  <td>
                    {{mem.initDate?(new Date(mem.initDate)).toLocaleDateString():''}}
                  </td>
                  <td>
                    {{mem.lastPres?(dateFormatter.format(new Date(mem.lastPres))):''}}
                  </td>
                  <td>
                    {{mem.totalPres!=0 ? mem.totalPres:''}}
                  </td>
                  <td v-for="(date,index) in dates" :key="date" :style="[index>0? {'background':'gray'}:{}]">
                    <AttendBox @changed="onAttendChanged" :position="index" :student="mem"
                      :disabled="index>0" :style="[index==0?{'cursor':'pointer'}:{}]"
                      :ref="'at_' + mem.cwId + ',' + index" :presence="mem.attends[index]"/>
                  </td>
              </tr>
          </tbody>
        </table>
        </div>

      </div>
    </div>
  </div>
  <p style="margin-bottom:1cm;"></p>

  <div class="scrollingtable">
    <div>
      <div class="tableparent">
        <div class="table-container">
        <table v-if="recentEvents.length" class="table is-striped">
          <caption class="is-size-4" :title="curClassId">
            Actions For {{curClassName}} ({{recentEvents.length}})
          </caption>
          <thead>
              <tr>
                <th v-for="colH in colHeadersActions" :key="colH">
                  <!-- <div :label="colH"></div> -->
                  {{ colH }}
                </th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="sim in recentEvents.slice().reverse()" :key="sim.eventId" :ref="'ev_' + sim.eventId" 
                  :class="selectedEvent==sim && 'is-selected'">
                  <td class="firstcol has-text-left" >{{sim.sid in cwidStu ? cwidStu[sim.sid].fn+' '+cwidStu[sim.sid].ln : ''}}</td>
                  <td>{{sim.sid}}</td>
                  <td class="has-text-left">{{mapFriendly(sim.wsMethod)}}</td>
                  <td :title="(new Date(sim.ts + ' UTC')).toLocaleDateString()">{{(new Date(sim.ts + ' UTC')).toLocaleTimeString()}}</td>
                  <td>{{sim.unitIDIn}}</td>
                  <td>{{sim.unitIDOut}}</td>
                  <td>{{sim.complete=='nan'?'':sim.complete}}</td>
                  <td>{{sim.eventId}}</td>
              </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>

  <div :class="clselModalStyling()" tabindex="-1">
    <div class="modal-background"></div>
    <div class="modal-card">
      <div class="modal-content">
        <div class="box has-background-white has-text-left ">
        
        <div v-if="classList && classList.length" >
          <div class="table-container">
          <table class="table is-fullwidth is-hoverable fullwidth is-striped">
            <caption class="is-size-4">Choose a Class ({{classList.length}})</caption>
            <thead>
                <tr>
                  <th class="firstcol is-one-fifth">id</th>
                  <th>Name</th>
                  <th>School</th>
                </tr>
            </thead>
            <tbody v-for="c in classList" :key="c.id" v-on:click="clickClassSel(c)">
                <tr class="clickable-row" :style="{'cursor':'pointer'}">
                    <td class="firstcol is-one-fifth has-text-left" >{{c.id}}</td>
                    <td>{{c.name}}</td>
                    <td>{{c.schoolText}}</td>
                </tr>
            </tbody>
          </table>
          </div>
          <input type="checkbox" id="checkincprevday" v-model="isPrevDayEventsInc" class="ml-5 rightpadding">
          <label for="checkincprevday">Include previous day action events</label>
        </div>

        <div v-if="! (classList && classList.length)">
          <p>No classes associated with this user.</p>
          <p style="margin-bottom:1cm;"></p>
          <div class="panel-block is-justify-content-space-between">
            <button :class="clselButtonStyle()" @click="closeClselModal">Close</button>
          </div>
        </div>

        </div>
      </div>
    </div>
  </div>

  <div :class="modalStyling()"  @keyup.esc="closeNoteModal" tabindex="-1">
    <div class="modal-background"></div>
    <div class="modal-card">
      <div class="modal-content">

        <div class="box has-background-white has-text-left ">
          <div v-if="currentStudent">
            <h2 class="is-size-4 has-text-centered">
              {{currentStudent.fn}} {{currentStudent.ln}}
            </h2>
            <details><summary>Details</summary>
              <div style="padding-left: 50px;">
                <div>sid:{{currentStudent.id}}</div>
                <div>cw_id:{{currentStudent.cwId}}</div>
                <div>username:{{currentStudent.username}}</div>
              </div>
            </details>
            <div>phone1:{{currentStudent.ph1}}</div>
            <div>phone2:{{currentStudent.ph2}}</div>
            <div>email:{{currentStudent.email}}</div>
            <p style="margin-bottom:10px;"></p>
            <div class="form" method="dialog">

              <details @toggle="toggleNewNote"><summary>Add New Note...</summary>
              <div class="newnotepanel">
                <div v-if="isAddingNote">
                  <div>
                    <label>Title </label><input class="inbox" type="text" v-model="modTitle" placeholder="required">
                  </div>
                  <div>
                    <label>Description <textarea class="inbox" v-model="modDescription"></textarea></label>
                  </div>
                  <span>Linked to: </span>
                  <select class="form-control" name="template" v-model="modNewNoteBaseSelected">
                      <option v-for="bn in baseNotes" v-bind:value="bn" :key="bn.id">
                        {{ bn.id }}: {{ bn.title }}
                      </option>
                  </select>
                  <input type="checkbox" id="checkbox" v-model="modStatusClose" class="ml-5 rightpadding">
                  <label for="isAddingNote">Resolve (linked note)</label>
                  <div class="panel-block is-justify-content-space-between">
                    <button class="button is-success is-pulled-right" 
                      @click="modSaveNote(currentStudent)" :disabled="!(modTitle)">
                      Save
                    </button>
                  </div>
                </div>
              </div>
              </details>

              <div v-if="modNotes">
                <div v-for="n in modNotes.slice().reverse()" :key="n.id">
                  <hr>
                  <div :style="[n.status ? {'background':'yellow'} : {}]">
                    <p>
                    Note {{n.id}}:           <i>{{n.title}}</i>
                    </p>
                    <p>
                    {{n.detail}}
                    </p>
                    <span>By: {{n.author}}&nbsp;&nbsp;&nbsp;On: {{n.createdAt.toDateString()}}
                    &nbsp;&nbsp;&nbsp;Status: {{n.status?'Open':'Closed'}}
                    </span>
                  </div>
                </div>
              </div>

              <p style="margin-bottom:1cm;"></p>
              <div class="panel-block is-justify-content-space-between">
                <button class="button is-pulled-right" @click="closeNoteModal">Close</button>
              </div>
            </div>
          </div>
        </div>

      </div><!-- end of modal-content -->
    </div>
    <!-- <button class="modal-close is-large" aria-label="close"></button> -->
  </div>

</template>

<script>
import _ from 'lodash'
import AttendBox from './attendbox.vue'
//import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { TW_CLASS_ID, TW_CLASS_NAME, ENDPOINT } from '../constants/settings'
import { membCompare } from '../util'

const DEBOUNCEMS = 2000      // 500
const ACT_FRIENDLY = { 'login': 'Login',
                       'rl_start': 'Login Success',
                       'rl_end': 'Logout Success',
                       'rl_phrpt_start': 'Report Open',
                       'rl_phrpt_end': 'Report End',
                       'rl_assmt_sta': 'Assess Start',
                       'rl_mathmin_sta': 'WebMath Start',
                       'rl_xtramath_sta': 'XtraMath Start',
                       'rl_phact_start': 'ETCO Open',
                       'cwauth': 'ETCO Start',
                       'evalanswersheet': 'ETCO Badge',
                       'rl_phact_end': 'ETCO End',
                       'storeanswersheet': 'ETCO Save',      // ?
}

const MEMBERS_QUERY = gql`
query classMembers($cwId: Int!, $roleId: Int!, $classId: Int!) { etcoclassmember(cwId: $cwId, roleId: $roleId, classId: $classId) { id cwId username pw fn ln schId schdscr statusId statdscr eldId elddscr gender initDate grade gLn gFn ph1 ph2 email address city state country zip birthdate classname cldscr classId classschId svId lastPres totalPres} }
`
const QUERY_ATTENDHISTORY = gql`
query attendHist($classId: Int!, $dtAfter: DateTime!, $dtUptoinc: DateTime!) { attendancesclass(classId:$classId, dtAfter:$dtAfter, dtUptoinc:$dtUptoinc) { id cwId when presence } }
`
const QUERY_ACTIONS = gql`
query newActions($classId: Int!, $dtAfter: DateTime!, $dtUptoinc: DateTime!, $curDay: DateTime!) { 
  etcoaction(classId: $classId, dtAfter: $dtAfter, dtUptoinc: $dtUptoinc) { eventId wsMethod sid unitIDOut unitIDIn complete rewardCode ts }
  attendstats(classId: $classId, dt: $curDay) { cwId count }
  twlaparams { refresh }
  notestats(classId: $classId) { cwId numOpen numClosed }
}
`
const MUTATE_CREATENOTE = gql`
mutation newNote($cwId: Int!, $svId: Int!, $title: String!, $detail: String!, $status: Int!, $supercedes: Int!) { createNote(cwId: $cwId, svId:$svId, title:$title, detail:$detail, status:$status, supercedes:$supercedes) { note { id cwId svId title status detail supercedes status} } }
`
const MUTATE_CREATEATTEND = gql`
mutation newAttend($cwId: Int!, $classId: Int!, $when: DateTime!, $svId: Int!, $presence: Int!) { createAttendance(cwId:$cwId, classId:$classId, when:$when, svId:$svId, presence:$presence) { attendance { id cwId classId when svId presence} } }
`
const NOTES_QUERY = gql`
query studentNotes($cwId: Int!) { notes(cwId: $cwId) { id supercedes cwId svId status visible title detail author createdAt} }
`
const NOTESTATS_QUERY = gql`
query getNoteStats($classId: Int!) { notestats(classId: $classId) { cwId numOpen numClosed } }
`
const QUERY_LISTCLASSES = gql `
query getClasses($cwId: Int!, $roleId: Int!) { etcoclass(cwId: $cwId, roleId: $roleId) { id name schoolText } }
`
// these sid have over 200 actions in our simdata
//const actSidOver200 = ['156548', '166520', '173177', '173290', '173328', '173329', '175828', '176074', '180098', '181721', '182616', '182891', '183068', '183074', '183100', '183102', '183104', '183105', '183108', '183518', '183545']
//const studentNames = ['Robert Peters', 'Gordon Dowd', 'David Wright', 'Theresa Murray', 'Caroline Payne', 'Joan Jones', 'Peter Metcalfe', 'Tim Forsyth', 'Anne May', 'Joe Lawrence', 'Chloe MacDonald', 'Oliver Wilkins', 'Oliver Churchill', 'Sean Parsons', 'Sam Ball', 'Charles Lambert', 'Diane North', 'David Nolan', 'Brandon Clark', 'Anthony Johnston']
const NULLNOTE = {id: 0, supercedes: 0, title: "None"}

//const fifteenMin = 900000     // ticks
const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const DAYSTOTAL = 8
function dateAddSeconds(dt, secs) {   // eslint-disable-line no-unused-vars
      let newdate = new Date(dt)
      newdate.setSeconds(newdate.getSeconds() + secs)
      return newdate
}
function dateAddDays(dt, days) {      // eslint-disable-line no-unused-vars
      let newdate = new Date(dt)
      newdate.setDate(newdate.getDate() + days)
      return newdate
}
function rotPresence(old) {
    // rotating -1, 0, 1
    let newVal = ((old + 1 + 1) % 3) - 1
    return newVal
}
export default {
  name: 'App',
  inject: ['meta'],
  created() {
    this.meta.setTitle('TW Attendance Dashboard');
  },
  components: {
    //HelloWorld,
    AttendBox
  },
  setup () {
    // better to do this at mount time
    //const { result, loading, error } = useQuery(MEMBERS_QUERY, {...})
  },
  data() { return {
      dateFormatter: new Intl.DateTimeFormat('en-US', {timeZone: 'UTC'}),   // to display dates without timezone adjustments
      attendPersists: [],
      isPrevDayEventsInc: false,
      selectedEvent: null,
      dates: null,
      datePosition: null,
      //students: studentNames,
      //sidStu: {},
      cwidStu: {},
      isSimulating: false,
      isReplaying: false,
      timestart: 0,
      timeend: 100,
      timecur: 40,
      timeclock: null,
      lastTimecur: null,
      recentEvents: [],
      currentStudent: null,
      modalActive: false,
      cwidAction: {},
      classmembers: null,
      refreshInterval: 5000,
      //ticksStart: 0,
      //ticksElapsed: 0,
      timeReplay: new Date(Date.parse('2021-11-04 13:12:00.000Z')),   // first action happens toward the end of that minute
      isAddingNote: false,
      modDescription: '',
      modTitle: '',
      modNotes: null,
      modStatusClose: false,
      modNewNoteBaseSelected: NULLNOTE,
      cwidNoteStats: {},
      //pollStartTime: null,
      //pollEndTime: null,
      curClassId: null,
      curClassName: null,
      classList: null,
      clselModalActive: false,
      pollingSafe: false,
      pollStart: null,
      replayOffset: 0,
    }
  },
  computed: {
    seenTodayCount() {
      return this.classmembers ? (this.classmembers.reduce((acc, a) => acc + (a.seenCount||a.attendsConfirmed[0]?1:0), 0)) : 0
    },
    presentToday() {
      return this.classmembers ? (this.classmembers.reduce((acc, a) => acc + (a.attends[0]==1?1:0), 0)) : 0
    },
    isReplaySupported() {
      let uid = this.userId
      return (uid && (uid==153752))  // for now, only support replay mode for remoteadmin user
    },
    userId() {
      return this.$root.$data.userId
    },
    baseNotes() {
      //let bn = this.modNotes.filter(n => (! n.supercedes) && n.status)  // is a base note and not closed
      let bn = this.modNotes.filter(n => n.status)  // since we are not auto closing related - all not closed
      bn.unshift(NULLNOTE)
      return bn
    },
    replayClock() {
      //let showTime = new Date(this.timeReplay.getTime() + this.ticksElapsed)
      if(this.pollStart) {
        let showTime = new Date(this.pollStart.getTime() - this.replayOffset)
        return showTime.toLocaleString()
      } else {
        return ''
      }
    },
    sortedMembers() {
      if(this.classmembers) {
        let sm = this.classmembers.map(item => Object.assign({}, item, { comp: null }))
        sm.sort(membCompare)
        return sm
      } else {
        return []
      }
    },
    colHeaders() { 
      let h = ['Student', 'Notes (o/c)', 'Last Action', 'Last Unit', 'Gr', 'School', 'Guardian', 'Instructor', 'Start Date', 'Last Pres', 'Total Pres']
      if(this.dates) {
        let ds = this.formDates(this.dates)
        h = h.concat(ds)
      }
      return h
    },
    colHeadersActions() { 
      let h = ['\nStudent', '\ncw_id', '\nAction', 'Local Time', 'Unit In', 'Unit Out', 'Complete', '\nid']     // sid ACTUALLY is cw_id
      return h
    },
    // it is important to instantiate a new Date object instead of calling Date(timestamp) which gives offset results
    timeProgress() { return (new Date(this.timecur)).toLocaleString() + ' [' + this.timecur + '] '},
    clock() {
      if(this.timeclock) return this.timeclock
      else return new Date(Date.now()).toLocaleString()
    }
  },
  methods: {
    onReport() {
      let url = ENDPOINT + '/reportdownload/' + this.curClassId
      window.open(url, '_blank')    // prefer new window or tab, but not guaranteed
    },
    onChangeClass() {
      // this is a horrible hack to address the extreme painpoint of needing to relogin to change class
      this.curClassId = ""
      this.curClassName = ""
      localStorage.setItem(TW_CLASS_ID, this.curClassId)
      localStorage.setItem(TW_CLASS_NAME, this.curClassName)
      //this.$forceUpdate()
      location.reload()
    },
    clickClassSel(c) {
      this.curClassId = c.id
      this.curClassName = c.name
      localStorage.setItem(TW_CLASS_ID, this.curClassId)
      localStorage.setItem(TW_CLASS_NAME, this.curClassName)
      this.closeClselModal()  // the only way to get out of this modal - app functionality dead without knowing class anyway
      this.fillRosterList()
    },
    toggleNewNote(ev) {
      //console.log(ev)
      this.isAddingNote = ev.target.open
    },
    persistAttend: _.debounce(function() {
      this.attendPersists.forEach((a)=>{
        let dt = this.dates[a.position]
        this.$apollo.mutate({mutation:MUTATE_CREATEATTEND, 
                            variables:{cwId: a.student.cwId, classId: this.curClassId,
                              when: dt.toISOString(), svId: this.getSvId(), presence: a.student.attends[a.position]
                            }
        }).then((response) => {
          console.log(response.data)
          let att = response.data.createAttendance.attendance
          let when = new Date(att.when.substr(0,19).replace('T', ' ') + ' UTC')
          let pos = this.findDatePosition(when)
          this.cwidStu[att.cwId].attendsConfirmed[pos] = att.presence
        }).catch((response) => {
          console.log(response)
        })
      }, this)
      this.attendPersists = []
    }, DEBOUNCEMS),
    onAttendChanged(a) {     //position, presence, student) {
      a.student.attends[a.position] = rotPresence(a.presence)
      if(! this.attendPersists.includes(a)) {
        this.attendPersists.push(a)
      }
      this.persistAttend()
    },
    getSvId() {
      return this.userId
    },
    modSaveNote(stu) {
      console.log(`modSaveNote ${this.modTitle} ${this.modDescription}`)
      console.log(stu)
      console.log(this.modNewNoteBaseSelected)
      let baseNote = this.modNewNoteBaseSelected.id ? this.modNewNoteBaseSelected.id : 0
      console.log(`baseNote is ${baseNote}`)
      this.$apollo.mutate({mutation:MUTATE_CREATENOTE, 
                          variables:{ cwId: stu.cwId, svId: this.getSvId(), title: this.modTitle, 
                                      detail: this.modDescription, status: this.modStatusClose?0:1,
                                      supercedes: baseNote
                          }
      }).then((response) => {
        console.log(response.data.createNote.note)
        console.log(this.modNotes)
        // close local note if there was a reference to it (will be in sync with backend)
        if(this.modStatusClose && this.modNewNoteBaseSelected.id) {
          let basenote = this.modNotes.find(n => n.id == this.modNewNoteBaseSelected.id)
          basenote.status = 0
        }
        //this.isAddingNote = false     //leave it open in case they want to add another one
        this.modDescription = ''
        this.modTitle = ''
        this.modNewNoteBaseSelected = NULLNOTE
        this.modStatusClose = false
        this.modNotes.push(response.data.createNote.note)
        this.updateNoteStats()  // TODO: should only do this a single time on exiting modal if there was at least one save
      }).catch((response) => {
        console.log(response)
      })
    },
    resetElapsed() {
      this.isReplaying = false
      this.replayOffset = 0
      this.pollingSafe = false      // TODO: we should be able to recover back into normal mode but for now minimize browser impact
      //this.ticksStart = 0
      //this.ticksElapsed = 0
    },
    formatNoteStats(op, cl) {
      if(op || cl) {
        return op + ' / ' + cl
      }
      return '--'
    },
    formatLastUnit(act) {
      if(act) {
        return act.unitIDOut
      }
    },
    mapFriendly(s) {
      let sl = s.toLowerCase()
      if(sl in ACT_FRIENDLY) {
        return ACT_FRIENDLY[sl]
      }
      return s
    },
    formatLastact(act) {
      if(act) {
        return this.mapFriendly(act.wsMethod)
      }
      return '--'
    },
    openClselModal() {
      this.clselModalActive = true
      this.$apollo.query({query:QUERY_LISTCLASSES, fetchPolicy:'no-cache', variables:{cwId: this.userId, roleId: 6}
      }).then((response) => {
        console.log(response.data)
        this.classList = response.data.etcoclass
      }).catch((response) => {
        console.log(response)
      })
    },
    clselModalStyling() { // class select
      let st = 'modal'
      if (this.clselModalActive) {
        st += ' is-active'
      }
      return st
    },
    clselButtonStyle() {
      let style = 'button is-pulled-right ' 
      if(! this.classList) {
        style += 'is-loading'
      }
      return style
    },
    modalStyling() {
      let st = 'modal'
      if (this.modalActive) {
        st += ' is-active'
      }
      return st
    },
    showAct(act) {
      if(act) {
        let ref = 'ev_' + act.eventId
        //console.log(ref)
        //console.log(this.$refs[ref])
        this.$refs[ref][0].scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.selectedEvent = act
      }
    },
    openNoteModal(e) {
      this.modalActive = true
      this.currentStudent = e
      //this.$refs.notemodal.showModal()

      this.$apollo.query({query:NOTES_QUERY, fetchPolicy:'no-cache', variables:{cwId: this.currentStudent.cwId}
      }).then((response) => {
        this.modNotes = response.data.notes
        this.modNotes.forEach(x => {x.createdAt = this.pyDtToDate(x.createdAt)})
        console.log(this.modNotes)
      }).catch((response) => {
        console.log(response)
      })
    },
    closeClselModal() {
      this.clselModalActive = false
    },
    closeNoteModal() {
      //this.$refs.notemodal.close()
      this.modalActive = false
      this.currentStudent = null
    },
    formDates(ds) { 
      let formatted = []
      ds.forEach(x => {formatted.push(this.formDate(x))})
      return formatted
    },
    formDate(d) {
      return (1 + d.getMonth()) + '/' + d.getDate() + '\n' + DAYS[d.getDay()]
    },
    tooltip(memb) {
      return `cw_id\t\t\t${memb.cwId}\nSeen Today:\t\t${memb.seenCount}\n\nClick for Student Profile`
    },
    startSim() {
      // calc offset now back to start time this.timeOffset
      //this.ticksStart = new Date().getTime()
      //this.timecur = this.timestart
      this.recentEvents = []
      this.isReplaying = true
      //this.countdownTimer()
      let now = new Date()
      this.replayOffset = now.getTime() - this.timeReplay.getTime()
      this.setDates(this.timeReplay)
    },
    startPoll() {
      // set up the start time
      let now = new Date()   //now
      this.pollStart = new Date(now.getTime())
      if(this.isReplaySupported) {
        console.log('replay mode detected - extending event history to previous day')
        // an alternative to replay mode is we will fetch events going back to yesterday
        //this.pollStart = dateAddDays(this.pollStart, -1)  //a day ago, TURNING THIS OFF - MAYBE CONFUSING/DANGEROUS
      }
      if(this.isPrevDayEventsInc) {
        console.log('Extending event history to previous day')
        this.pollStart = dateAddDays(this.pollStart, -1)  //a day ago
      }
      this.pollStart.setHours(0)      // get recent history back to the start of the day
      this.pollStart.setMinutes(0)
      this.pollStart.setSeconds(0)
      this.pollingSafe = true
      this.poll()
    },
    poll() {
      if(this.pollingSafe) {
        setTimeout(() => {
          if(this.pollingSafe) {
            let now = new Date()
            let start = this.pollStart
            let finish = now
            if(this.isReplaying) {
              // then the queries will be = new Date(orig.getTime() - offset)
              start = new Date(start.getTime() - this.replayOffset)
              finish = new Date(finish.getTime() - this.replayOffset)
            }
            //console.log(`querying from: ${start} to ${finish}`)
            this.$apollo.query({query:QUERY_ACTIONS, 
                                variables:{
                                  classId: this.curClassId,
                                  dtAfter: start.toISOString(), 
                                  dtUptoinc: finish.toISOString(),
                                  curDay: finish.toISOString(),
                                }
            }).then((response) => {
              this.refreshInterval = response.data.twlaparams.refresh
              //console.log(response.data.twlaparams.refresh)
              this.recentEvents.push(...response.data.etcoaction)
              let act = response.data.etcoaction
              let cwidAction = {}
              for (let a in act) {
                cwidAction[act[a].sid] = act[a]  // WARNING event.sid is ACTUALLY a cw_id
              }
              this.cwidAction = cwidAction
              this.classmembers.forEach((m) => {
                if(m.cwId in this.cwidAction) {
                  m.lastact = this.cwidAction[m.cwId]
                }
              })    //at least we only have to make a single pass through the member list and adjust

              response.data.attendstats.forEach((a) => {
                if(a.cwId in this.cwidStu) {
                  this.cwidStu[a.cwId].seenCount = a.count
                }
              })    //cwId attendance handling

              this.hydrateNoteStats(response.data.notestats)

              this.poll()   //trigger the next one now?  is this wise?  maybe we should trigger it from the successful query

            }).catch((response) => {
              console.log(response)
            })
            this.pollStart = now
          }
        }, this.refreshInterval)
      }
    },
    tsParse(s) {
      let parts = s.split(/-|T|Z|:|\./)
      parts[1] -= 1   // zero based
      parts.pop()     // empty string
      return new Date(...parts)
    },
    hydrateNoteStats(noteStats) {
        //let noteStats = response.data.notestats
        let cwIdNs = {}
        for (let n in noteStats) {
          cwIdNs[noteStats[n].cwId] = noteStats[n]
        }
        this.cwidNoteStats = cwIdNs
        this.classmembers.forEach((m) => {
          if(m.cwId in this.cwidNoteStats) {
            m.noteStatsOpen = this.cwidNoteStats[m.cwId].numOpen
            m.noteStatsClosed = this.cwidNoteStats[m.cwId].numClosed
          }
        })
    },
    async updateNoteStats() {
      this.$apollo.query({query:NOTESTATS_QUERY, fetchPolicy:'no-cache', variables:{classId: this.curClassId}
      }).then((response) => {
        //console.log('notestats')
        //console.log(response.data.notestats)
        this.hydrateNoteStats(response.data.notestats)
      }).catch((response) => {
        console.log(response)
      })
    },
    async simulate() { 
      this.isSimulating = true
      //this.timeend = this.tsParse(this.simdata[0].ts).getTime() // reversed
      //this.timestart = this.tsParse(this.simdata[this.simdata.length-1].ts).getTime()
    },
    setDates(dt) {
      this.dates = this.datesAttend(dt)
      this.datePosition = {}
      this.dates.forEach((d,i) => {this.datePosition[d.valueOf()] = i})
    },
    datesAttend(dt) {
      // dt and 7 prev days
      let da = []
      let d = new Date(dt.getTime())
      d.setHours(0,0,0,0)     // remove time portion - we want to do comparison as strictly date sometimes
      for (let i=0; i<DAYSTOTAL; i++) { da.push(d); let n = new Date(d.getTime()); n.setDate(d.getDate()-1); d = n }
      return da
    },
    findDatePosition(dt) {  // find which horizontal position corresponds to the date from 0 to DAYSTOTAL-1, not found is -1
      let dtOnly = new Date(dt.getTime())
      dtOnly.setHours(0,0,0,0)
      let target = dtOnly.valueOf()
      if(target in this.datePosition) {
        return this.datePosition[target]
      } else {
        return -1
      }
    },
    fillRosterList() {
      this.$apollo.query({query:MEMBERS_QUERY, variables:{cwId: this.userId, roleId: 6, classId: this.curClassId}
      }).then((response) => {
        //console.log(response)
        // copy the object which is immutable and add some fields
        this.classmembers = response.data.etcoclassmember.map(item => Object.assign({}, item, 
                                                                { lastact: null, 
                                                                  noteStatsOpen: 0, 
                                                                  noteStatsClosed: 0, 
                                                                  seenCount: 0,
                                                                  attends: {0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0},
                                                                  attendsConfirmed: {0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0},
                                                                }))
        // create a fast lookup so attendance stats can be updated efficiently
        //this.sidStu = {}
        this.cwidStu = {}
        this.classmembers.forEach((m) => {
          this.cwidStu[m.id] = m
          this.cwidStu[m.cwId] = m
        })
        //console.log('sid to stu map')
        //console.log(this.cwidStu)

        this.updateNoteStats()
        this.attendanceHistory()

        // we have to wait for all this before we can start polling
        this.startPoll()
      }).catch((response) => {
        console.log(response)
      })
    },
    pyDtToDate(datestr) {
        // this is very gross - transform our python DateTimeField 
        // '2022-10-13T05:00:00+00:00' --> '2022-10-13 05:00:00 UTC'
        return new Date(datestr.substr(0,19).replace('T', ' ') + ' UTC')
    },
    attendanceHistory() {
      //let start = this.dates[DAYSTOTAL-1]
      //let finish = this.dates[0]
      // maximize range
      let start = new Date(this.dates[DAYSTOTAL-1].getTime())
      let finish = new Date(this.dates[0].getTime())
      start.setHours(0,0,0,0)
      finish.setHours(23,59,59,999)       // setUTCHours
      this.$apollo.query({query:QUERY_ATTENDHISTORY, variables:{
                                  classId: this.curClassId,
                                  dtAfter: start.toISOString(),
                                  dtUptoinc: finish.toISOString() }
      }).then((response) => {
        console.log('Initial attendances')
        console.log(response.data.attendancesclass)
        // copy the object which is immutable and add some fields
        let att = response.data.attendancesclass
        att.forEach((a) => { 
          let stu = this.cwidStu[a.cwId]
          if(stu) {
            let when = this.pyDtToDate(a.when)

            let pos = this.findDatePosition(when)
            if(-1 == pos) {
              console.log('Problem assigning attendance for')
              console.log(a)
            } else {
              stu.attends[pos] = a.presence
              stu.attendsConfirmed[pos] = a.presence
            }
          } else {
            console.log(`action student not found in class ${a.cwId}`)
          }
        })

        // we have to wait for all this before we can start polling
      }).catch((response) => {
        console.log(response)
      })
    }
  }, // methods
  mounted() {
    //console.log('Attend component mounted')
    window.compAttend = this
    this.curClassId = localStorage.getItem(TW_CLASS_ID)
    this.curClassName = localStorage.getItem(TW_CLASS_NAME)

    this.setDates(new Date())
    //this.stuSid = {}

    if(this.curClassId) {
      this.fillRosterList()
    } else {
      this.openClselModal()
    }

  },
}
</script>

<style>
.rightpadding {
 margin-right:4px;
}
.newnotepanel {
  background-color: #EBEBEB;
}
.inbox {
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
table,
td {
    border: 1px solid #333;
}
thead,
tfoot {
    background-color: #333;
    color: #fff;
}
/* TODO: having problems getting the first column to be smaller - this is part of it */
.fullwidth {
  width: 100%;
}
.firstcol {
    text-align: left;
}
/* ---- slider ---- */
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}
.border {
  border: 2px blue dashed;
}

.ml-0 {
  margin-left: 0;
}
.mr-auto {
  margin-right:auto;
}
.d-block {
  display:block;
}


/*.scrollingtable */

/* this kinda works but header doesnt match the body
thead, tbody { display: block; }
tbody {
    height: 600px;        Just for the demo          
    overflow-y: auto;     Trigger vertical scroll   
    overflow-x: hidden;   Hide the horizontal scroll 
} */

/* simplest solution for scrolling table body with fixed header - but tweaking needed */
th {
  position: sticky;
  top: 0;
  z-index: 2;
}
.tableparent {
  overflow-y: auto; max-height: 600px;
}





html {
	/*width: 100%;*/ /*required if using % width*/
	/*height: 100%;*/ /*required if using % height*/
}
body {
	/*width: 100%;*/ /*required if using % width*/
	/*height: 100%;*/ /*required if using % height*/
	/*margin: 0;*/ /*required if using % width or height*/
	/*padding: 0 20px 0 20px;*/ /*purely aesthetic, not required*/
  /*box-sizing: border-box;*/ /*required if using above declaration*/
  background: white;
	text-align: center; /*delete if using % width, or you don't want table centered*/
}

details summary { cursor: pointer; }

input{box-sizing:border-box} 
textarea {
  box-sizing: border-box;
  resize: vertical;
}


</style>
