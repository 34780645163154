import { createApp, provide, h } from 'vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client/core'
import { TW_USER_ID, TW_USER_NAME, TW_AUTH_TOKEN, ENDPOINT } from './constants/settings'
import meta from './meta'

//import { HttpLink } from '@apollo/client';
import 'bulma/css/bulma.css'

import App from './App.vue'

const cache = new InMemoryCache()

// changing over from uri setting to lower level link setting so we can use middleware
const httpLink = new HttpLink({
  uri: ENDPOINT + '/graphql'                  // 'http://viaje.local:8000/graphql',     // TODO: make this configurable
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem(TW_AUTH_TOKEN)
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null
    }
  })

  return forward(operation)
})

const apolloClient = new ApolloClient({
    cache,
    //uri: 'http://viaje.local:8000/graphql',
    link: authMiddleware.concat(httpLink)
})

let userId = localStorage.getItem(TW_USER_ID)
let userName = localStorage.getItem(TW_USER_NAME)

const appOptions ={
  setup () {
        provide(DefaultApolloClient, apolloClient)
      },
  render: () => h(App),
  data() { return {   // will be generally available as $root.userId
      userId,
      userName,
    }
  },
  provide: {
    meta: meta
  },
}
const app = createApp(appOptions)

// this should not be necessary - maybe my misunderstanding of provider model, but without it this.$apollo is undefined in the app -chris
app.config.globalProperties.$apollo = apolloClient

window.appRoot = app.mount('#app')

